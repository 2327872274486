import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueMeta from "vue-meta";
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faGolfBall } from "@fortawesome/free-solid-svg-icons";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import JQuery from "jquery";
import $Scriptjs from 'scriptjs';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import VueSocialSharing from 'vue-social-sharing'
import VueCarousel from 'vue-carousel';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import * as CryptoJS from 'crypto-js';
import Carousel3d from 'vue-carousel-3d';

library.add(
  faFacebook, 
  faInstagram,
  faTwitter,
  faYoutube,
  faArrowDown,
  faArrowUp,
  faAngleRight,
  faSearch,
  faUser,
  faChevronRight,
  faLock,
  faGolfBall,
  faVolumeUp
);
Vue.use(VueLodash, { name: 'custom' , lodash: lodash })
Vue.use(VueperSlides, VueperSlide);
Vue.use(VueCarousel);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSocialSharing);
Vue.use(Carousel3d);
new Vue({
  router,
  store,
  axios,
  JQuery,
  lodash,
  $Scriptjs,
  CryptoJS,
  render: h => h(App)
}).$mount("#app");
